import React from "react"
import Hs from "../images/h-sperator.svg"
//import { Link } from "gatsby"
//import Image from "../components/Image"

/*
 can have
 cssClass
 bgImage
 col1 class
 col2 class
 col1
 col2

*/
const TwoColsContainer = ({
  title = '',
  col1,
  col2,
  className = '',
  titleClassName = '',
  colsClassName = 'lg:space-x-32 lg:space-x-reverse',
  col1ClassName = 'lg:w-1/2',
  col2ClassName = 'lg:w-1/2',
  bgImage = false,
  ...props
}) => {



  const bgStyle = {}
  if (bgImage !== false && bgImage.publicURL !== '') {
    bgStyle.backgroundImage = `url('${bgImage.publicURL}')`
  }
  return (
    <section
      className={"bg-no-repeat " + className}
      style={bgStyle}
    >
      <div className="container space-y-8 lg:px-6 px-1">
        {title && <h2 className={"text-[34px] text-blue " + titleClassName}><img src={Hs} alt="" className="inline ml-1.5" />{title}</h2>}
        <div className={"flex flex-col lg:flex-row " + colsClassName} >
          <div className={" relative " + col1ClassName}>
            {col1}
          </div>

          <div className={" z-20 " + col2ClassName}  >
            {col2}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColsContainer