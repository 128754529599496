import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import getMetatags from "../util/getMetatags"
import TextBox from "../components/text-box"
import Hero from "../components/hero"
import TwoColsContainer from "../components/two-cols-container"

const Basic1Page = ({ data, ...props }) => {

  const seo = getMetatags(data.nodeInnerPage1.metatag/* , nodeOverview.relationships.OGImage?.localFile?.publicURL */)
  const { hero, double } = data.nodeInnerPage1.relationships

  return (
    <Layout>
      <Seo {...seo} />
      <Hero
        className="mt-[90px] lg:mt-[126px]  h-[147px] lg:h-[350px] "
        {...hero}
        url={hero.relationships.image.localFile.publicURL}
      />
      {/* <TwoColsContainer
        title={twoCol.title}
        titleClassName={'font-bold'}
        className="my-12 px-4 lg:px-0"
        col1={<div className="text-lg html-block text-justify" dangerouslySetInnerHTML={{ __html: twoCol.html1.processed }} />}
        col2={<div className="text-lg html-block text-justify" dangerouslySetInnerHTML={{ __html: twoCol.html2.processed }} />}
      /> */}
      <TwoColsContainer
        className="px-4 lg:px-0"
        col1={<TextBox
          title={double[0].title}
          titleClassName={'font-bold'}
          html={double[0].html.processed}
          className="mb-12 py-8"
          htmlClassName="text-justify"
        />}
        col2={<TextBox
          title={double[1].title}
          titleClassName={'font-bold'}
          html={double[1].html.processed}
          className="p-0 lg:py-8"
          htmlClassName="text-justify"
        />}
      />
      <TwoColsContainer
        className="px-4 lg:px-0"
        col1={<TextBox
          title={double[2].title}
          titleClassName={'font-bold'}
          html={double[2].html.processed}
          className="mb-12 py-8"
          htmlClassName="text-justify"
        />}
        col2={<TextBox
          title={double[3].title}
          titleClassName={'font-bold'}
          html={double[3].html.processed}
          className="p-0 lg:py-8"
          htmlClassName="text-justify"
        />}
      />
      {/*  <TwoColsContainer
        className="px-4 lg:px-0"
        col1={<TextBox
          title={single.title}
          titleClassName={'font-bold'}
          html={single.html.processed}
          className="mb-12 py-8"
          htmlClassName="text-justify"
        />}
        col2={<div className="h-full bg-[url('/backgrounds/pattern1.svg')] bg-no-repeat bg-left-top"
        ></div>}
      /> */}

    </Layout >
  )
}

export default Basic1Page

export const Head = () => <title>Basic1 Page</title>

export const query = graphql`
  query Basic1Query($id: String!) {
    nodeInnerPage1(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {
        hero: field_hero {
          ...Hero
        }
        double:field_paragraphx2 {
          ...TitleHtml
        }
      }
    }
  }
`
