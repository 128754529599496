import React from "react"
import { Link } from "gatsby"
import Hs from "../images/h-sperator.svg"


const TextBox = ({ title, description = false, html = false, link = false, className = 'bg-white', htmlClassName = '', showTitleDecoration = true, titleClassName = '', children, ...props }) => {
  return (
    /*  <div className={`lg:w-[653px]  bg-white p-10 lg:p-20 z-10 mx-auto shadow-lg ${className}`} > */
    <div className={`mx-auto space-y-8  ${className}`} >

      {title && <h3 className={"text-[28px] lg:text-[34px] text-blue " + titleClassName}>{showTitleDecoration && <img src={Hs} alt="" className="inline ml-1.5" />}{title}</h3>}
      {description && <p className="text-lg" >{description}</p>}
      {html && <div className={"text-lg html-block " + htmlClassName} dangerouslySetInnerHTML={{ __html: html }} />}

      {link && <Link to={link.uri} className="text-yellow2 text-lg">{link.title}</Link>}
      {children}
    </div>
  )
}


const TextBoxH2 = ({ title, description = false, html = false, link = false, className = 'bg-white', htmlClassName = '', showTitleDecoration = true, titleClassName = '', children, ...props }) => {
  return (
    /*  <div className={`lg:w-[653px]  bg-white p-10 lg:p-20 z-10 mx-auto shadow-lg ${className}`} > */
    <div className={`mx-auto space-y-8  ${className}`} >

      {title && <h2 className={"text-[28px] lg:text-[34px] text-blue " + titleClassName}>{showTitleDecoration && <img src={Hs} alt="" className="inline ml-1.5" />}{title}</h2>}
      {description && <p className="text-lg" >{description}</p>}
      {html && <div className={"text-lg html-block " + htmlClassName} dangerouslySetInnerHTML={{ __html: html }} />}

      {link && <Link to={link.uri} className="text-[#19538c] text-lg" aria-label={link.title}>{link.title}</Link>}
      {children}
    </div>
  )
}

export default TextBox
export {TextBoxH2}